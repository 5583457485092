import {ListModel} from '@web/core/models/api/core/list.model'

export const EMPTY_LIST: ListModel<any> = {
  results: [],
  count: 0,
  next: true,
}

export const LIST_LAZY_END_REACH_THRESHOLD = 100

export const SPECIFIC_TARGET_GRANT_UNIVERSITY_IDS = [82, 101, 110]
